<template>
    <b-card-code
        title="Add New" 
    >
          <!-- form -->
          <validation-observer
            ref="UserAddForm"
            #default="{invalid}"      
          >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="addUser"
          >
                <!-- Email -->
                <b-form-group
                  label="Email"
                  label-for="email">
                    
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="email|required">
                        <b-form-input
                          id="email"
                          v-model="email"
                          :state="errors.length > 0 ? false:null"
                          name="email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <!-- Password -->
                <b-form-group
                  label="Password"
                  label-for="Password">
                    
                    <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required">
                        <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid':null"
                        >
                            <b-form-input
                            id="password"
                            v-model="password"
                            :state="errors.length > 0 ? false:null"
                            :type="passwordFieldType"
                            name="password"
                            placeholder="············"
                            />
                            <b-input-group-append is-text>
                                <feather-icon
                                    class="cursor-pointer"
                                    :icon="passwordToggleIcon"
                                    @click="togglePasswordVisibility"
                                />
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <!-- First Name -->
                <b-form-group
                  label="First Name"
                  label-for="first Name">
                    
                    <validation-provider
                      #default="{ errors }"
                      name="first_name"
                      rules="required">
                        <b-form-input
                          id="first_name"
                          v-model="first_name"
                          :state="errors.length > 0 ? false:null"
                          name="first_name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <!-- First Name -->
                <b-form-group
                  label="Last Name"
                  label-for="Last Name">
                    
                    <validation-provider
                      #default="{ errors }"
                      name="last_name"
                      rules="required">
                        <b-form-input
                          id="last_name"
                          v-model="last_name"
                          :state="errors.length > 0 ? false:null"
                          name="last_name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

               <b-form-group
                  v-if="is_staff_login"
                  label=""
                  label-for="Is staff">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="is_staff"
                        name="is_staff"
                        value="true"
                        unchecked-value="false"
                        >
                        Is Staff (System User)?
                    </b-form-checkbox>
                </b-form-group>


                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="invalid"
                >
                  Add
                </b-button>
            </b-form>
          </validation-observer>

  </b-card-code>
</template>
<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BForm, BButton,BFormTextarea, BInputGroupAppend,BInputGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import { BTable } from 'bootstrap-vue'
// import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol, 
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox
  },
  data() {
    return {
      is_staff_login:this.$store.state.app.user.is_staff,
      email:"",
      password:"",
      first_name: "",
      last_name: "",
      is_staff: true,

      role:"",

      roles:[{ value: null, text: '--Select Role--' }],
    }
  },
  created: function () {
     this.load();
  },
  methods: {

    load: function(){
        if(!this.is_staff_login)
          this.is_staff = false
        var self = this;
        // Load oragnization drop down

        // Load role dropdown
        const r_options = {
              method: 'GET',
              headers: { 'content-type': 'application/json' },
              url: process.env.VUE_APP_BASEURL+'user/groups/',
          }
          this.$http(r_options).then( ( res ) => {
            console.log(res.data)
            res.data.map(function(value, key) {
                let role = {
                    value: res.data[key], 
                    text: res.data[key].name
                    };
                // console.log(res.data[key].profile.organization)
                self.roles.push(role)
             });
          })
    },

    addUser: function() {
        this.$refs.UserAddForm.validate().then(success => {
        if (success) {
          //call to axios
            let data = {
                username: this.email,
                email:this.email,
                password:this.password,
                is_active:true,
                first_name:this.first_name,
                last_name:this.last_name,
                is_staff:this.is_staff
            }

            if((this.is_staff === false || this.is_staff ==='false') && this.role){
                let groups_list =[]
                groups_list.push(this.role)
                data['groups'] = groups_list
            }
            console.log(data)
            const options = {
              method: 'POST',
              headers: { 'content-type': 'application/json' },
              data:data,
              url: process.env.VUE_APP_BASEURL+'user/users/',
          }
          this.$http(options).then( ( res ) => {
            console.log(res)
            if(res.data.id){
               this.$router.push({name: 'Users'});
            }
          })
        }  
        })
      }
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  }
}
</script>